import { Link } from "gatsby";
import React from "react";
import Logo from "../images/logo-fond-rouge.jpg";

function Footer() {

  return (
<footer>
<div className="bg-gray-800 mt-12">
  <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-8 lg:px-8">
    <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 xl:mt-0">
      <div className="mt-12 md:mt-0">
        <img className="h-16 rounded-full" src={Logo} alt="Deltavisit" />
        <p className="mt-4 text-gray-100 text-base leading-6">
        Delta Visit – Agence de communication digitale<br/>
        3 rue Paul Rocache<br/>31100 Toulouse</p>
        <p className="mt-4 text-gray-100 text-base leading-6">
        <svg className="h-6 w-6 pr-1 inline-block" fill="currentColor" viewBox="0 0 24 24">
          <path d="M13.04 14.69l1.07-2.14a1 1 0 011.2-.5l6 2A1 1 0 0122 15v5a2 2 0 01-2 2h-2A16 16 0 012 6V4c0-1.1.9-2 2-2h5a1 1 0 01.95.68l2 6a1 1 0 01-.5 1.21L9.3 10.96a10.05 10.05 0 003.73 3.73zM8.28 4H4v2a14 14 0 0014 14h2v-4.28l-4.5-1.5-1.12 2.26a1 1 0 01-1.3.46 12.04 12.04 0 01-6.02-6.01 1 1 0 01.46-1.3l2.26-1.14L8.28 4z"/>
        </svg>
        <a href="tel:0033582951635" title="Appelez nous">05 82 95 16 35</a>
        </p>
        <div className="mt-4 flex">
          <a href="https://www.facebook.com/Deltavisit-112881366866641/" rel="noopener noreferrer" target="_blank" className="text-white hover:text-gray-100" title="Deltavisit sur Facebook">
            <span className="sr-only">Facebook</span>
            <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
              <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd"/>
            </svg>
          </a>
          <a href="https://www.instagram.com/deltavisit/" rel="noopener noreferrer" target="_blank" className="ml-6 text-white hover:text-gray-100" title="Deltavisit sur Instagram">
            <span className="sr-only">Instagram</span>
            <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
              <path fillRule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clipRule="evenodd"/>
            </svg>
          </a>
          <a href="https://twitter.com/deltavisit" rel="noopener noreferrer" target="_blank" className="ml-6 text-white hover:text-gray-100" title="Deltavisit sur Twitter">
            <span className="sr-only">Twitter</span>
            <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
              <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"/>
            </svg>
          </a>
          </div>
        </div>
      <div className="mt-12 md:mt-0">
            <h4 className="text-sm leading-5 font-semibold tracking-wider text-white uppercase">
              Activités couvertes
            </h4>
            <ul className="mt-4">
              <li>
                <Link to="/visites-virtuelles/immobilier" className="text-base leading-6 text-gray-100 hover:text-gray-500">
                  Immobilier
                </Link>
              </li>
              <li className="mt-4">
                <Link to="/visites-virtuelles/hotels-gites" className="text-base leading-6 text-gray-100 hover:text-gray-500">
                  Hôtels et Gites
                </Link>
              </li>
              <li className="mt-4">
                <Link to="/visites-virtuelles/locations-saisonnieres" className="text-base leading-6 text-gray-100 hover:text-gray-500">
                  Locations saisonnieres
                </Link>
              </li>
              <li className="mt-4">
                <Link to="/visites-virtuelles/restaurants" className="text-base leading-6 text-gray-100 hover:text-gray-500">
                  Restaurants et commerces
                </Link>
              </li>
              <li className="mt-4">
                <Link to="/visites-virtuelles/espaces-culturels" className="text-base leading-6 text-gray-100 hover:text-gray-500">
                  Espaces culturels
                </Link>
              </li>
            </ul>
        </div>
      <div className="mt-12 md:mt-0">
            <h4 className="text-sm leading-5 font-semibold tracking-wider text-white uppercase">
              Réalisations
            </h4>
            <ul className="mt-4">
              <li>
                <Link to="/realisations/immobilier" className="text-base leading-6 text-gray-100 hover:text-gray-500">
                  Immobilier
                </Link>
              </li>
              <li className="mt-4">
                <Link to="/realisations/hotels-gites" className="text-base leading-6 text-gray-100 hover:text-gray-500">
                  Hôtels et Gites
                </Link>
              </li>
              <li className="mt-4">
                <Link to="/realisations/locations-saisonnieres" className="text-base leading-6 text-gray-100 hover:text-gray-500">
                  Locations saisonnieres
                </Link>
              </li>
              <li className="mt-4">
                <Link to="/realisations/restaurants" className="text-base leading-6 text-gray-100 hover:text-gray-500">
                  Restaurants et commerces
                </Link>
              </li>
              <li className="mt-4">
                <Link to="/realisations/espaces-culturels" className="text-base leading-6 text-gray-100 hover:text-gray-500">
                  Espaces culturels
                </Link>
              </li>
            </ul>
          </div>
      <div className="mt-12 md:mt-0">
            <h4 className="text-sm leading-5 font-semibold tracking-wider text-white uppercase">
              Informations
            </h4>
            <ul className="mt-4">
              <li>
                <Link to="/agence" title="Qui sommes nous ?" className="text-base leading-6 text-gray-100 hover:text-gray-500">
                  Qui sommes nous ?
                </Link>
              </li>
              <li className="mt-4">
                <Link to="/contact" title="Nous contacter" className="text-base leading-6 text-gray-100 hover:text-gray-500">
                  Nous contacter
                </Link>
              </li>
              <li className="mt-4">
                <Link to="/mentions-legales" title="Mentions légales" className="text-base leading-6 text-gray-100 hover:text-gray-500">
                  Mentions légales
                </Link>
              </li>
            </ul>
          </div>
    </div>
    <div className="mt-12 border-t border-gray-200 pt-8">
      <p className="text-sm leading-6 text-white xl:text-center">
        &copy; 2020 Delta Visit. Tous droits réservés. <Link to="/mentions-legales" className="text-sm leading-6 text-gray-100 hover:text-gray-500">Mentions légales</Link> | Réalisation: <a href="https://www.timetobefirst.com" rel="noopener noreferrer" target="_blank" className="text-sm leading-6 text-gray-100 hover:text-gray-500">Agence Time To Be First</a>
      </p>
    </div>
  </div>
</div>
</footer>
  );
}

export default Footer;
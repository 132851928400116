import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState } from "react";
import Logo from "../images/logo.png";

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <header>
      <div className="bg-gray-50 overflow-hidden">
        <div className="pt-6 pb-6">
            <div className="max-w-screen-xl mx-auto px-4 sm:px-6">
              <nav className="relative flex items-center justify-between sm:h-10 lg:justify-center">
                <div className="flex items-center flex-1 lg:absolute lg:inset-y-0 lg:left-0">
                  <div className="flex items-center justify-between w-full lg:w-auto">
                    <Link to="/">
                      <img className="h-16 w-auto sm:h-20" src={Logo} alt={site.siteMetadata.title} />
                    </Link>
                    <div className="-mr-2 flex items-center lg:hidden">
                      <button aria-label="Menu" type="button" onClick={() => toggleExpansion(!isExpanded)} className="inline-flex items-center justify-center p-2 rounded-md text-red-400 hover:text-red-500 hover:bg-red-100 focus:outline-none focus:bg-red-100 focus:text-red-500 transition duration-150 ease-in-out">
                        <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="hidden lg:block text-gray-500">
                  <Link title="Visite virtuelle" to="/visites-virtuelles" className="font-medium hover:text-red-500 focus:outline-none focus:text-red-500 transition duration-150 ease-in-out" activeClassName="text-red-500">Visite virtuelle</Link>
                  <Link title="Photographie" to="/photographie" className="ml-10 font-medium hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out" activeClassName="text-red-500">Photographie</Link>
                  <Link title="Réalisations" to="/realisations" className="ml-10 font-medium hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out" activeClassName="text-red-500">Réalisations</Link>
                  <Link title="Notre agence" to="/agence" className="ml-10 font-medium hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out" activeClassName="text-red-500">Notre agence</Link>
                </div>
                <div className="hidden lg:absolute lg:flex lg:items-center lg:justify-end lg:inset-y-0 lg:right-0">
                  <span className="inline-flex rounded-md shadow">
                    <Link to="/contact" className="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-red-600 bg-white hover:text-red-500 focus:outline-none focus:shadow-outline-blue active:bg-gray-50 active:text-red-700 transition duration-150 ease-in-out">
                      Contactez-nous
                    </Link>
                  </span>
                </div>
              </nav>
            </div>
        
            <div className={`${
                  isExpanded ? `block` : `hidden`
                } absolute top-0 left-0 right-0 md:left-auto p-2 md:w-1/2 lg:hidden z-10`}>
              <div className="rounded-lg shadow-md transition transform origin-top-right">
                <div className="rounded-lg bg-white shadow-xs overflow-hidden">
                  <div className="px-5 pt-4 flex items-center justify-between">
                    <div>
                      <img className="h-8 w-auto" src={Logo} alt={site.siteMetadata.title} />
                    </div>
                    <div className="-mr-2">
                      <button aria-label="Fermer le menu" type="button" onClick={() => toggleExpansion(!isExpanded)} className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
                        <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="px-2 pt-2 pb-3 text-gray-700">
                    <Link title="Visite virtuelle" to="/visites-virtuelles" className="block px-3 py-2 rounded-md text-base font-medium hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" activeClassName="text-red-500">Visite virtuelle</Link>
                    <Link title="Photographie" to="/photographie" className="mt-1 block px-3 py-2 rounded-md text-base font-medium hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" activeClassName="text-red-500">Photographie</Link>
                    <Link title="Réalisations" to="/realisations" className="mt-1 block px-3 py-2 rounded-md text-base font-medium hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" activeClassName="text-red-500">Réalisations</Link>
                    <Link title="Notre agence" to="/agence" className="mt-1 block px-3 py-2 rounded-md text-base font-medium hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" activeClassName="text-red-500">Notre agence</Link>
                  </div>
                  <div>
                    <Link to="/contact" title="Contactez-nous" className="block w-full px-5 py-3 text-center font-medium text-red-600 bg-gray-50 hover:bg-gray-100 hover:text-red-700 focus:outline-none focus:bg-gray-100 focus:text-red-700 transition duration-150 ease-in-out">
                      Contactez-nous
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </header>
  );
}

export default Header;
